import { groupBy, mapValues, maxBy, meanBy } from 'lodash'
import { medianBy } from './utils/median'

export const mediaAttributes = [
  {
    theme: 'Convenience',
    statement: 'Is simple and easy for customers to use/access',
    industryScore: 80.4736842105263,
    importance: 10.8572313800777,
    stdScore: 14.66147156,
  },
  {
    theme: 'Convenience',
    statement: 'Is available when customers need them',
    industryScore: 78.3947368421053,
    importance: 5.47794800326628,
    stdScore: 11.46684875,
  },
  {
    theme: 'Reliability',
    statement: 'Consistently meets customers’ expectations',
    industryScore: 80.0,
    importance: 10.5957551459881,
    stdScore: 13.91868276,
  },
  {
    theme: 'Reliability',
    statement: 'Has fast resolution of customers’ problems',
    industryScore: 81.8157894736842,
    importance: 11.2166151959327,
    stdScore: 13.24377521,
  },
  {
    theme: 'Reliability',
    statement: 'Listens to customers/is responsive',
    industryScore: 81.0,
    importance: 7.32763956646293,
    stdScore: 12.24303442,
  },
  {
    theme: 'Trust',
    statement: 'Protects customers’ data',
    industryScore: 85.2368421052632,
    importance: 4.56378114330253,
    stdScore: 11.28604546,
  },
  {
    theme: 'Trust',
    statement: 'Respects customers’ privacy',
    industryScore: 83.3157894736842,
    importance: 5.48279058348088,
    stdScore: 14.19955322,
  },
  {
    theme: 'Trust',
    statement: 'Ratings & reviews are available',
    industryScore: 79.2894736842105,
    importance: 4.51081464381926,
    stdScore: 13.55251626,
  },
  {
    theme: 'Trust',
    statement: 'Is knowledgeable about their product/services',
    industryScore: 85.0526315789474,
    importance: 5.72298619145917,
    stdScore: 12.31555239,
  },
  {
    theme: 'Personalization',
    statement: 'Knows their customers',
    industryScore: 79.7631578947368,
    importance: 2.21560793735806,
    stdScore: 11.88101873,
  },
  {
    theme: 'Personalization',
    statement: 'Understands the customers’ needs',
    industryScore: 80.0263157894737,
    importance: 9.52695792538625,
    stdScore: 11.40290822,
  },
  {
    theme: 'Personalization',
    statement: 'Communications are personalized to the customer',
    industryScore: 79.5,
    importance: 1.68287731194345,
    stdScore: 13.57212963,
  },
  {
    theme: 'Personalization',
    statement: 'Recommends products/services that are relevant to the customer',
    industryScore: 79.1315789473684,
    importance: 4.14771495937322,
    stdScore: 15.87820375,
  },
  {
    theme: 'Personalization',
    statement:
      'Has a consistent experience across all interactions (online/offline)',
    industryScore: 77.8157894736842,
    importance: 5.68131875916353,
    stdScore: 16.35229592,
  },
  {
    theme: 'Value',
    statement: 'Offers loyalty programs that reward customers',
    industryScore: 74.4210526315789,
    importance: 3.50874476976448,
    stdScore: 24.32064142,
  },
  {
    theme: 'Delight',
    statement: 'Surprises/delights customers',
    industryScore: 79.0789473684211,
    importance: 3.53220697283684,
    stdScore: 15.7949386,
  },
  {
    theme: 'Expertise',
    statement:
      'Educates customers about how to better use their product/service',
    industryScore: 77.7368421052632,
    importance: 3.949009510384733,
    stdScore: 15.68211963,
  },
]
// export const themes = attributes.reduce(
//   (themes, { theme }) =>
//     !themes.includes(theme) ? [...themes, theme] : themes,
//   []
// )

export const mediaAttributeThemeColors = {
  Convenience: 'blue.500',
  Reliability: 'green.500',
  Trust: 'purple.500',
  Personalization: 'pink.500',
  Value: 'yellow.500',
  Delight: 'blue.600',
  Expertise: 'green.600',
}
export const mediaThemeImportance = [
  { theme: 'Convenience', importance: 24.5579449750136 },
  { theme: 'Reliability', importance: 19.7478601387334 },
  { theme: 'Trust', importance: 15.8648597973326 },
  { theme: 'Personalization', importance: 12.3730985912809 },
  { theme: 'Value', importance: 9.46204106729998 },
  { theme: 'Delight', importance: 9.26821324182209 },
  { theme: 'Expertise', importance: 8.72598218851743 },
]
// export const attributeThemeColorsTokenized = Object.keys(
//   attributeThemeColors
// ).map(attribute => useToken('colors', attributeThemeColors[attribute]))
export const mediaThemeIndustryPerformance = mapValues(
  groupBy(mediaAttributes, 'theme'),
  statements => meanBy(statements, 'industryScore')
)

export const mediaThemes = mediaThemeImportance.map(themeEntry => ({
  ...themeEntry,
  color: mediaAttributeThemeColors[themeEntry.theme],
  industryScore: mediaThemeIndustryPerformance[themeEntry.theme],
  importance: mediaThemeImportance.find(
    entry => entry.theme === themeEntry.theme
  ).importance,
}))

export const mediaMedianThemeImportance = medianBy(mediaThemes, 'importance')
export const mediaMedianThemePerformance = medianBy(
  mediaThemes,
  'industryScore'
)
export const mediaMedianAttributeImportance = medianBy(
  mediaAttributes,
  'importance'
)
// export const mediaMedianAttributePerformance = medianBy(mediaAttributes, 'industryScore')
export const mediaMaxAttributeImportance = maxBy(
  mediaAttributes,
  'importance'
).importance
export const mediaAttributesOfImportantThemes = mediaAttributes.filter(
  attribute =>
    mediaThemes.find(t => t.theme === attribute.theme).importance >
    mediaMedianThemeImportance
)
