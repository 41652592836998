// import React from 'react'
//
// import { usePageVisibility } from './components/utils/useVisibility'
// import { differenceBy } from 'lodash'
// import { PollContext } from '../App'

// export async function unauthApiFetch(
//   endpoint,
//   { logData = false, logPerf = true, method = 'GET', blob = false, data } = {}
//   ){
//   if (!endpoint) return
//   let t = performance.now()
//   let options = {
//     method,
//   }
//   if (['POST', 'PUT'].includes(method)) {
//     if (data) {
//       options.body = JSON.stringify(data)
//       options.headers['Content-Type'] = 'application/json'
//       options.headers['Access-Control-Expose-Headers'] = 'Location'
//     }
//   }
//   let status = 0
//   let statusText = ''
//   let _headersReceived = undefined
//
// }

export async function apiFetch(
  endpoint,
  token = undefined,
  logout = () => {},
  { logData = false, logPerf = true, method = 'GET', blob = false, data } = {}
) {
  let t = performance.now()
  if (!endpoint) {
    return
  }
  const authenticatedRequest = typeof token === 'string'
  let options = {
    ...(authenticatedRequest
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : { headers: {} }),
    method,
  }
  if (['POST', 'PUT'].includes(method)) {
    if (data) {
      options.body = JSON.stringify(data)
      options.headers['Content-Type'] = 'application/json'
      options.headers['Access-Control-Expose-Headers'] = 'Location'
    }
  }
  let status = 0
  let statusText = ''
  let _headersReceived = undefined
  return await fetch(endpoint, options)
    .then(res => {
      status = res.status
      if (status === 204) return

      statusText = res.statusText
      _headersReceived = Object.fromEntries(res.headers.entries())

      if (blob) {
        return res.blob()
      }
      return res.json()
    })
    .then(data => {
      if (logData) {
        console.log(data)
      }
      switch (status) {
        case 200:
        case 201:
        case 400:
          if (['POST', 'PUT'].includes(method)) {
            data = { ...data, _headersReceived }
          }
          return data
        case 204:
          return
        case 401:
          logout()
          throw new Error(data.message)
        default:
          if (data.message) {
            throw new Error(data.message)
          }
          console.error(data)
          throw new Error(
            `${status}: Unexpected Error ${
              statusText ? ' - ' + statusText : ''
            }`
          )
      }
    })
    .catch(err => {
      throw new Error(
        `${status}: Unexpected Error ${statusText ? ' - ' + statusText : ''}`,
        { cause: err }
      )
    })
    .finally(() => {
      if (logPerf) {
        console.info(`${endpoint}: ${(performance.now() - t).toFixed(0)}ms`)
      }
    })
}
