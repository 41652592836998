import { emailRegex } from './regex'
import { titleCase } from './strings'

export default function getEmailFromParams(params) {
  const email = params.get('email')
  if (email && emailRegex.test(email)) {
    console.log('VALID EMAIL:', email, params)
    return email
  }
  console.log('INVALID EMAIL:', email, params)
  return null
}
export function getTypeFromParams(params) {
  const type = params.get('type') === 'Tech' ? 'Tech' : 'Media'
  return titleCase(type)
}
