import React from 'react'
import { apiFetch } from './apiFetch'
import { useSearchParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

export default function useMagicTokenAuth(setMessage) {
  const [searchParams] = useSearchParams()
  const queryParamToken = searchParams.get('t')
  const [user, setUser] = React.useState({ email: null })
  const [token, setToken] = React.useState(queryParamToken)

  const [loading, setLoading] = React.useState(
    queryParamToken ? 'Magic' : 'Initializing'
  )

  const logout = React.useCallback(() => {
    setUser({ email: null })
    setToken(null)
    window.localStorage.setItem('_token', null)
  }, [])

  React.useEffect(
    function getStoredToken() {
      if (queryParamToken) {
        setLoading('Magic')
        return
      }

      const storedToken = window.localStorage.getItem('_token')
      if (storedToken !== 'null' && storedToken) {
        setToken(storedToken)
        setLoading('Verifying')
        return
      }
      setLoading(false)
    },
    [queryParamToken]
  )

  React.useEffect(
    function verifyMagicLinkToken() {
      /**
       * Verifies a token from an email, requests a longer lasting one.
       * The token will be loaded into state, triggering one more verification
       * from a useEffect tied to the token.
       * */
      if (!queryParamToken || loading !== 'Magic') {
        return
      }
      setLoading('Fetching')
      document.body.style.cursor = 'progress'
      apiFetch('/api/verify_magic_token', queryParamToken, () => {
        setUser({ email: null })
      })
        .then(({ token, email, role }) => {
          setToken(token)
          setUser({ email, role })
        })
        .catch(error => {
          setMessage(error?.message)
        })
        .finally(() => {
          document.body.style.cursor = 'default'
          setLoading('Verifying')
        })
    },
    [loading, queryParamToken, setMessage]
  )

  const toast = useToast()

  React.useEffect(
    function verifyToken() {
      if (loading !== 'Verifying') {
        return
      }
      setLoading('Fetching')
      apiFetch('/api/me', token, () => {
        console.info('INFO: INVALID TOKEN')
        logout()
      })
        .then(user => {
          if (user === undefined) {
            throw new Error('Invalid login. Try requesting a new magic link.')
          }
          window.localStorage.setItem('_token', token)
          setUser(user)
          console.info('INFO: VALID TOKEN')
        })
        .catch(error => {
          if (
            error?.message === '500: Unexpected Error  - Internal Server Error'
          ) {
            toast({
              title: `Authentication Error`,
              description: `Error 500: Unable to contact the server. Check your internet connection, and refresh the page.`,
              status: 'success',
              duration: 1500,
              isClosable: true,
              position: 'bottom-right',
            })
          } else {
            setMessage(error?.message)
            logout()
          }
        })
        .finally(() => setLoading(false))
    },
    [token, user, loading, logout, setMessage, toast]
  )

  return {
    token,
    loading,
    user,
    logout,
  }
}
