import { Box, Link, Flex, Heading } from '@chakra-ui/react'
import { Logo } from '../Logo'
import React from 'react'
import LinkButton from './LinkButton'
import { getTypeFromParams } from '../utils/getFromParams'

export default function Navbar({ userEmail, logout, children, ...props }) {
  const url = new URL(window.location.href)
  const type = getTypeFromParams(url.searchParams)
  const id = url.searchParams.get('id')
  const adminPortal = url.pathname.includes('/admin')
  return (
    <Box
      h={'78.25px'}
      // px={'16.10px'}
      pt={1.5}
      p={4}
      // pb={5}
      sx={{ '@media print': { position: 'static', bg: 'white' } }}
      {...props}
      w={'full'}
      // bg={'rgb(244, 244, 246)'}
      // bg={'whiteAlpha.500'}
      position={'fixed'}
      top={0}
      zIndex={2}
      opacity={1}
      bg={'bg.500'}
      // bg={'rgba(255,255,255,0.05)'}
      // backdropFilter={'blur(10px) saturate(100%)'}
      // borderBottom={'1.5px solid'}
      borderColor={'blackAlpha.100'}
    >
      <Flex alignItems={'center'}>
        <Link href={'https://www.actioniq.com/cx-iq/'} ml={'72px'}>
          <Logo h={'45px'} _hover={{ filter: 'brightness(1.2)' }} />
        </Link>
        <Heading
          // pt={5}
          ml={'100px'}
          fontSize={'3xl'}
          // letterSpacing={'wide'}
          fontWeight={'black'}
        >
          {adminPortal
            ? 'Admin Portal'
            : type === 'Tech'
            ? 'Tech Scorecard'
            : 'Media Scorecard'}
        </Heading>
        <Flex alignItems={'center'}>
          {children}
          {/*<Flex w={'full'} px={4} gridColumnGap={4}>*/}
          {/*  <ColorModeSwitcher />*/}
          {/*  <Menu>*/}
          {/*    <MenuButton*/}
          {/*      as={Button}*/}
          {/*      rounded={'full'}*/}
          {/*      variant={'link'}*/}
          {/*      cursor={'pointer'}*/}
          {/*      minW={0}*/}
          {/*    >*/}
          {/*      <Avatar*/}
          {/*        size={'sm'}*/}
          {/*        name={`${userEmail[0]} ${userEmail.slice(1)}`}*/}
          {/*      />*/}
          {/*    </MenuButton>*/}
          {/*    <MenuList*/}
          {/*      fontSize={'md'}*/}
          {/*      backdropFilter={'blur(15px) saturate(100%)'}*/}
          {/*      background={useColorModeValue('white', 'rgba(0,0,0,0.5)')}*/}
          {/*    >*/}
          {/*      <MenuItem*/}
          {/*        icon={<ExternalLinkIcon />}*/}
          {/*        onClick={logout}*/}
          {/*        color={'red.500'}*/}
          {/*      >*/}
          {/*        Logout*/}
          {/*      </MenuItem>*/}
          {/*    </MenuList>*/}
          {/*  </Menu>*/}
          {/*</Flex>*/}
        </Flex>
        {/*<ColorModeSwitcher />*/}
        {id !== null ? (
          <LinkButton
            href={'https://www.actioniq.com/cx-iq/'}
            position={'absolute'}
            right={'72px'}
            // mt={'12px'}
            // my={'auto'}
          >
            Return to ActionIQ
          </LinkButton>
        ) : null}
      </Flex>
    </Box>
  )
}
