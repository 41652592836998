import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/900.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/100.css'
import '@fontsource/dm-serif-display'
// import '@fontsource/roboto/700.css'
// import '@fontsource/roboto/900.css'
// import '@fontsource/roboto/300.css'
// import '@fontsource/roboto/100.css'
// import '@fontsource/nunito-sans/900.css'
// import '@fontsource/nunito-sans/800.css'
// import '@fontsource/nunito-sans/600.css'
import { theme } from './theme'
import Footer from './components/Footer'
import UnprotectedApp from './UnprotectedApp'
import { Navigate, Route, Routes } from 'react-router-dom'
import useMagicTokenAuth from './auth/useMagicToken'
import MagicLinkForm from './auth/components/MagicLinkForm'
import RequireAuth from './auth/components/RequireAuth'
import ProtectedApp from './ProtectedApp'

export const AppContext = React.createContext()

function App() {
  const [message, setMessage] = React.useState('')
  const { token, loading, user, logout } = useMagicTokenAuth(setMessage)

  const appData = React.useMemo(
    () => ({
      appName: 'ActionIQ Scorecard App',
      user: user,
      token: token,
      logout,
    }),
    [token, user, logout]
  )
  return (
    <AppContext.Provider value={appData}>
      <ChakraProvider theme={theme}>
        <Routes>
          <Route path="/" element={<UnprotectedApp />} />
          <Route
            path="/admin/login"
            element={
              loading ||
              appData.user === undefined ||
              appData.user.token === null ||
              appData.user.email === null ? (
                appData.token ? (
                  ''
                ) : (
                  <MagicLinkForm message={message} setMessage={setMessage} />
                )
              ) : (
                <Navigate to={'/admin'} />
              )
            }
          />
          <Route
            path={'/admin/*'}
            element={
              <RequireAuth redirectTo={'/admin/login'}>
                <ProtectedApp />
              </RequireAuth>
            }
          />
        </Routes>
        <Footer />
      </ChakraProvider>
    </AppContext.Provider>
  )
}

export default App
