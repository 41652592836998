import React from 'react'
import {
  Button,
  Link,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { AppContext } from './App'
import { apiFetch } from './auth/apiFetch'
import { useSearchParams } from 'react-router-dom'
import Navbar from './components/Navbar'
import Pagination from './components/Pagination'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { DownloadIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons'
import Footer from './components/Footer'
import SearchBox from './components/SearchBox'

dayjs.extend(relativeTime)

export default function ProtectedApp() {
  const { token, logout } = React.useContext(AppContext)
  const [scorecards, setScorecards] = React.useState()
  const [pagination, setPagination] = React.useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page') || 1
  const perPage = searchParams.get('per_page') || 10
  const search = searchParams.get('search') || ''

  React.useEffect(() => {
    const queryString = new URLSearchParams({
      page,
      per_page: perPage,
      ...(search.length > 0
        ? {
            search,
          }
        : {}),
    }).toString()
    apiFetch(`api/scorecards?${queryString}`, token, logout)
      .then(data => {
        setScorecards(data.items)
        const { next, prev } = data._links
        setPagination({
          page: data._meta.page,
          perPage: data._meta.per_page,
          totalItems: data._meta.total_items,
          next: next ? new URLSearchParams(next.split('?')[1]) : null,
          prev: prev ? new URLSearchParams(prev.split('?')[1]) : null,
        })
      })
      .catch(error => console.error(error))
  }, [logout, page, perPage, search, token])

  const handleSearch = search => {
    if (search === '') {
      setSearchParams({ page: 1, perPage })
    } else {
      setSearchParams({ page: 1, perPage, search })
    }
  }

  const handleRequestCSV = () => {
    apiFetch(
      `/api/scorecards_csv${search.length > 0 ? `?search=${search}` : ''}`,
      token,
      logout,
      { blob: true }
    )
      .then(data => {
        const href = window.URL.createObjectURL(data)
        const a = document.createElement('a')
        a.download = `export.csv`
        a.href = href
        a.click()
        a.href = ''
      })
      .catch(error => console.error(error))
  }
  return (
    <>
      <Navbar>
        <Pagination
          ml={7}
          mt={4}
          fontSize={'lg'}
          {...pagination}
          numItemsOnCurrentPage={scorecards ? scorecards.length : 0}
          position={'fixed'}
          right={7}
          top={2}
        >
          <SearchBox handleSubmit={handleSearch} />
        </Pagination>
      </Navbar>
      <Table>
        <Thead>
          <Tr>
            <Th>id</Th>
            <Th>type</Th>
            <Th>email</Th>
            <Th>link</Th>
            <Th>pdf</Th>
            <Th isNumeric>created</Th>
          </Tr>
        </Thead>
        <Tbody>
          {scorecards
            ? scorecards.map(scorecard => (
                <Tr key={scorecard.short_id}>
                  <Td>{scorecard.short_id}</Td>
                  <Td>{scorecard.type}</Td>
                  <Td>{scorecard.email}</Td>
                  <Td>
                    <Link
                      isExternal
                      color={'brand.500'}
                      // href={`${window.location.origin}/api/scorecard?id=${scorecard.short_id}`}
                      href={scorecard.link}
                    >
                      link <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Td>
                  <Td>
                    <Link
                      isExternal
                      color={'brand.500'}
                      // href={`/api/scorecard_dl/${scorecard.short_id}`}
                      href={scorecard.pdf}
                    >
                      pdf <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Td>
                  <Td isNumeric>
                    {dayjs().to(dayjs(scorecard.created))}
                    <Tooltip
                      label={`${dayjs(scorecard.created).format(
                        'M/D/YY h:mm A'
                      )}`}
                    >
                      <InfoIcon
                        ml={1}
                        fontSize={'sm'}
                        // position={'absolute'}
                        // transform={'translate(34px, 5.8px)'}
                        color={'gray.400'}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              ))
            : null}
        </Tbody>
        {scorecards && scorecards.length === 0 ? (
          <TableCaption>No Scorecards</TableCaption>
        ) : null}
      </Table>
      <Pagination
        mr={7}
        mt={4}
        fontSize={'lg'}
        {...pagination}
        numItemsOnCurrentPage={scorecards ? scorecards.length : 0}
        float={'right'}
      >
        <Button
          size={'sm'}
          variant={'ghost'}
          mr={2}
          onClick={handleRequestCSV}
          rightIcon={<DownloadIcon />}
        >
          CSV
        </Button>
      </Pagination>
      <Footer />
    </>
  )
}
