import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  mediaAttributes,
  mediaAttributeThemeColors,
} from '../MediaScorecardData'
import { techAttributes, techAttributeThemeColors } from '../techScorecardData'
import ScoreSlider from './ScoreSlider'
import React from 'react'
import { getTypeFromParams } from '../utils/getFromParams'

export default function PerformanceScoringTable({ handleScoreChange, scores }) {
  const url = new URL(window.location.href)
  const type = getTypeFromParams(url.searchParams)
  const attributes = type === 'Media' ? mediaAttributes : techAttributes
  const attributeThemeColors =
    type === 'Media' ? mediaAttributeThemeColors : techAttributeThemeColors
  const id = url.searchParams.get('id')
  return (
    <Box>
      <Table
        mt={7}
        fontSize={'md'}
        sx={{ '@media print': { width: '800px !important' } }}
      >
        <Thead>
          <Tr
            sx={{
              '& th': {
                fontWeight: 'semibold',
                fontSize: '11.25pt',
                letterSpacing: 'wide',
                fontFamily: 'Inter',
                // color: 'blackAlpha.700',
                color: 'gray.800',
              },
            }}
          >
            <Th>Theme</Th>
            <Th isNumeric>Statement</Th>
            <Th
              sx={{
                '@media print': {
                  textAlign: 'right',
                },
              }}
              {...(id !== null ? { textAlign: 'right' } : {})}
            >
              Performance Score Input
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {attributes.map(({ theme, statement, importance }, i) => {
            return (
              <Tr key={i}>
                {/* <Td color={attributeThemeColors[theme]}>{theme}</Td> */}
                <Td>{theme}</Td>
                <Td
                  isNumeric
                  minWidth={{ xs: '200px', sm: '350px', xl: '500px' }}
                  sx={{ '@media print': { fontSize: '15px' } }}
                >
                  {statement}
                </Td>
                <ScoreSlider
                  statementIndex={i}
                  handleChange={score => handleScoreChange(statement, score)}
                  bgcolor={`${attributeThemeColors[theme]}`}
                  score={scores[statement].score}
                  minWidth={{
                    base: '300px',
                    lg: '400px',
                    xl: '450px',
                  }}
                  sx={{ '@media print': { minWidth: '20px' } }}
                />
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}
