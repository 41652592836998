import { Link } from '@chakra-ui/react'
import React from 'react'

const primary = {
  bg: 'action.500',
  // color: 'white',
  // border: '2px solid',
  // borderColor: 'action.500',
  _hover: {
    // bg: 'white',
    bg: 'action.600',
  },
}
const secondary = {
  // bg: 'white',
  // color: 'action.500',
  // border: '2px solid',
  // borderColor: 'action.500',
  _hover: {
    bg: '#BCBB9E',
    // color: 'white',
  },
}
const disabledStyle = {
  bg: 'white',
  color: 'gray.400',
  border: '2px solid',
  borderColor: 'gray.400',
  pointerEvents: 'none',
}

export default function LinkButton({
  children,
  variant = 'primary',
  disabled = false,
  ...props
}) {
  return (
    <Link
      textAlign={'center'}
      onClick={() => console.log('button clicked')}
      className={'no-print'}
      colorScheme={'action'}
      sx={{ '& svg': { display: 'inline', verticalAlign: 'middle' } }}
      {...(disabled
        ? disabledStyle
        : variant === 'primary'
        ? primary
        : secondary)}
      // border="2px solid"
      // bg={'action.500'}
      // color={'white'}
      // _hover={{
      //   bg: 'white',
      //   color: 'action.500',
      //   borderColor: 'action.600',
      // }}
      // bg={'white'}
      // color={'action.500'}
      // _hover={{
      //   bg: 'action.500',
      //   color: 'white',
      //   borderColor: 'action.600',
      // }}
      rounded={'4px'}
      // fontSize={'17px'}
      fontSize={'md'}
      // fontFamily={'"Nunito Sans", sans-serif'}
      fontWeight={'light'}
      transition={'all 0.2s linear'}
      px={8}
      py={3.5}
      paddingInlineStart={8}
      paddingInlineEnd={8}
      lineHeight={'18px'}
      _focus={{
        // outline: '2px solid',
        // outlineColor: 'action.600',
        outline: 'none',
      }}
      {...props}
    >
      {children}
    </Link>
  )
}
