export const median = arr => {
  if (!arr.length) return undefined
  const s = [...arr].sort((a, b) => a - b)
  const mid = Math.floor(s.length / 2)
  return s.length % 2 === 0 ? (s[mid - 1] + s[mid]) / 2 : s[mid]
}
export const medianBy = (arr, key) => {
  if (!arr.length) return undefined
  const s = [...arr.map(item => item[key])].sort((a, b) => a - b)
  const mid = Math.floor(s.length / 2)
  return s.length % 2 === 0 ? (s[mid - 1] + s[mid]) / 2 : s[mid]
}
