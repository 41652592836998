import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { AppContext } from '../../App.js'

const RequireAuth = ({ children, redirectTo }) => {
  let appData = React.useContext(AppContext)
  let isAuthenticated = !(
    appData.user.email === null || appData.user.token === null
  )
  let location = useLocation()
  // console.log('RequireAuth', { isAuthenticated, appData, location })
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} replace />
  )
}

export default RequireAuth
