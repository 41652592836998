import Chart from 'react-apexcharts'
import React from 'react'
import {
  mediaAttributeThemeColors,
  mediaThemeImportance,
  mediaThemes,
  mediaMedianThemeImportance,
  mediaMedianThemePerformance,
} from '../MediaScorecardData'
import {
  techAttributeThemeColors,
  techThemeImportance,
  techThemes,
  techMedianThemeImportance,
  techMedianThemePerformance,
} from '../techScorecardData'
import { Heading, Stack } from '@chakra-ui/react'
import { range } from 'lodash'
import logo from '../logo.svg'
import { getTypeFromParams } from '../utils/getFromParams'
// import ApexCharts from 'apexcharts'

const chakraColorToCSS = colorToken =>
  `var(--chakra-colors-${colorToken.replace('.', '-')})`

function getChartOptions(xRange, xMedian, yRange, yMedian, colors, type) {
  const themes = type === 'Media' ? mediaThemes : techThemes
  return {
    chart: {
      id: 'quad-map',
      fontFamily: 'Inter',
      selection: { enabled: false, stroke: { width: 0 } },
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
      },
    },
    states: {
      active: {
        filter: {
          type: 'none' /* none, lighten, darken */,
        },
      },
    },
    tooltip: {
      fillSeriesColor: true,
      theme: 'dark',
      enabled: true,
      x: {
        show: false,
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return `Performance: ${value}`
        },
      },
      y: {
        show: false,
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return `${value.toFixed(0)}%`
        },
      },
    },
    dataLabels: {
      enabled: true,
      distributed: true,
      formatter: function (val, opts) {
        return themes[opts.dataPointIndex].theme.toUpperCase()
      },
      textAnchor: 'middle',
      // offsetX: 6,
      offsetY: -6,
      background: {
        foreColor: chakraColorToCSS('gray.800'),
        padding: 10,
      },
      style: {
        fontWeight: 400,
      },
      // offsetY: 0.75,
      // colors: [opts => attributeThemeColors[opts.dataPointIndex]],
    },
    // title: {
    //   text: 'THEME PERFORMANCE + IMPORTANCE',
    //   align: 'center',
    //   style: {
    //     fontSize: '1rem',
    //     fontFamily: 'DM Serif Display',
    //     color: chakraColorToCSS('gray.600'),
    //   },
    //   offsetY: 35,
    // },
    markers: {
      discrete: colors.map((fillColor, i) => ({
        seriesIndex: 0,
        dataPointIndex: i,
        fillColor,
        size: 3,
        shape: 'circle',
        strokeColor: fillColor,
      })),
    },
    colors,
    annotations: {
      position: 'back',
      images: [
        {
          path: logo,
          width: 200,
          height: 20,
          x: '80%',
          y: '90%',
          offsetX: -10,
          offsetY: -10,
        },
      ],
      yaxis: [
        {
          position: 'back',
          y: yMedian,
          strokeDashArray: 0,
          borderColor: chakraColorToCSS('gray.200'),
        },
        // {
        //   position: 'front',
        //   y: yRange[1],
        //   y2: yMedian + 1,
        //   opacity: 0.25,
        //   fillColor: chakraColorToCSS('red.200'),
        //   width:
        //     ((xMedian - 2 - xRange[0]) / (xRange[1] - xRange[0])) * 100 + '%',
        //   strokeDashArray: 0,
        //   borderColor: chakraColorToCSS('red.200'),
        // },
      ],
      xaxis: [
        {
          x: xMedian,
          strokeDashArray: 0,
          borderColor: chakraColorToCSS('gray.200'),
        },
      ],
      points: [
        ...themes.map((theme, i) => ({
          x: xRange[0],
          y: yMedian - i * 1 - 3,
          marker: {
            size: 2,
            fillColor: chakraColorToCSS(theme.color),
            strokeColor: chakraColorToCSS(theme.color),
          },
          label: {
            text: theme.theme,
            textAnchor: 'start',
            offsetX: 10,
            offsetY: 17,
            borderWidth: 0,
            style: {
              // color: chakraColorToCSS(theme.color),
              background: 'transparent',
            },
          },
        })),
        {
          position: 'back',
          x: (xMedian - xRange[0]) / 2 + xRange[0],
          y: (yRange[1] - yMedian) / 2 + yMedian + yRange[0],
          marker: { size: 0 },
          label: {
            text: 'Improve',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '600',
              color: chakraColorToCSS('gray.300'),
              // color: chakraColorToCSS('red.200'),
            },
          },
        },
        {
          position: 'back',
          x: (xMedian - xRange[0]) / 2 + xRange[0],
          y: (yRange[1] - yMedian) / 2 + yMedian + yRange[0] - 1.5,
          marker: { size: 0 },
          label: {
            text: 'High Importance',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '400',
              color: chakraColorToCSS('gray.300'),
              // color: chakraColorToCSS('red.200'),
            },
          },
        },
        {
          position: 'back',
          x: (xMedian - xRange[0]) / 2 + xRange[0],
          y: (yRange[1] - yMedian) / 2 + yMedian - 3,
          marker: { size: 0 },
          label: {
            text: 'Low Performance',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '400',
              color: chakraColorToCSS('gray.300'),
              // color: chakraColorToCSS('red.200'),
            },
          },
        },
        {
          position: 'back',
          x: (xRange[1] - xMedian) / 2 + xMedian,
          y: (yRange[1] - yMedian) / 2 + yMedian,
          marker: { size: 0 },
          label: {
            text: 'Maintain',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '600',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        {
          position: 'back',
          x: (xRange[1] - xMedian) / 2 + xMedian,
          y: (yRange[1] - yMedian) / 2 + yMedian - 1.5,
          marker: { size: 0 },
          label: {
            text: 'High Importance',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '400',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        {
          position: 'back',
          x: (xRange[1] - xMedian) / 2 + xMedian,
          y: (yRange[1] - yMedian) / 2 + yMedian - 3,
          marker: { size: 0 },
          label: {
            text: 'High Performance',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '400',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        {
          position: 'back',
          x: (xMedian - xRange[0]) / 2 + xRange[0],
          y: (yMedian - yRange[0]) / 2 + yRange[0],
          marker: { size: 0 },
          label: {
            text: 'Ignore',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '600',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        {
          position: 'back',
          x: (xMedian - xRange[0]) / 2 + xRange[0],
          y: (yMedian - yRange[0]) / 2 - 1.5,
          marker: { size: 0 },
          label: {
            text: 'Low Importance',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '400',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        {
          position: 'back',
          x: (xMedian - xRange[0]) / 2 + xRange[0],
          y: (yMedian - yRange[0]) / 2 - 3,
          marker: { size: 0 },
          label: {
            text: 'Low Performance',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '400',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        {
          position: 'back',
          x: (xRange[1] - xMedian) / 2 + xMedian,
          y: (yMedian - yRange[0]) / 2 + yRange[0],
          marker: { size: 0 },
          label: {
            text: 'Deprioritize',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '600',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        {
          position: 'back',
          x: (xRange[1] - xMedian) / 2 + xMedian,
          y: (yMedian - yRange[0]) / 2 - 1.5,
          marker: { size: 0 },
          label: {
            text: 'Low Importance',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '400',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        {
          position: 'back',
          x: (xRange[1] - xMedian) / 2 + xMedian,
          y: (yMedian - yRange[0]) / 2 - 3,
          marker: { size: 0 },
          label: {
            text: 'High Performance',
            borderWidth: 0,
            offsetY: 0,
            style: {
              fontFamily: 'Inter',
              background: 'transparent',
              fontSize: '1rem',
              fontWeight: '400',
              color: chakraColorToCSS('gray.300'),
            },
          },
        },
        // X Axis Labels
        ...range(0, Math.round((xRange[1] - xRange[0]) / 10) + 1).map(i => ({
          position: 'back',
          x: 10 * i + xRange[0],
          y: yMedian - 1,
          marker: { size: 0 },
          label: {
            text: 10 * i + xRange[0],
            borderWidth: 0,
            style: {
              background: 'transparent',
              fontSize: '0.625rem',
              color: chakraColorToCSS('gray.500'),
            },
          },
        })),
        // Y Axis Labels
        ...range(0, 7).map(i => ({
          position: 'back',
          x: xMedian - 1.5,
          y: 5 * i + yRange[0] - 0.25,
          // y: 5 * i + yRange[0] - 0.45,
          marker: { size: 0 },
          label: {
            text: 5 * i + yRange[0] + '%',
            borderWidth: 0,
            style: {
              background: 'transparent',
              fontSize: '0.625rem',
              color: chakraColorToCSS('gray.500'),
            },
          },
        })),
      ],
    },
    xaxis: {
      type: 'numeric',
      crosshairs: {
        show: true,
        stroke: {
          dashArray: 3,
          width: 0.5,
          color: chakraColorToCSS('gray.300'),
        },
      },
      tooltip: {
        enabled: true,
      },
      tickAmount: 4,
      decimalsInFloat: 0,
      labels: { show: false },
      title: {
        text: 'Average Performance Score',
        style: {
          fontSize: '1rem',
          fontFamily: 'Inter',
          fontWeight: 300,
          color: chakraColorToCSS('gray.800'),
        },
      },
      floating: true,
      axisTicks: { show: false },
      axisBorder: {
        show: false,
      },
      min: xRange[0],
      max: xRange[1],
    },
    yaxis: {
      crosshairs: {
        show: true,
        stroke: {
          dashArray: 3,
          width: 0.5,
          color: chakraColorToCSS('gray.500'),
        },
      },
      tooltip: {
        enabled: true,
      },
      type: 'numeric',
      tickAmount: 3,
      decimalsInFloat: 0,
      labels: {
        show: false,
        formatter: val => `Importance: ${Math.round(val)}%`,
      },
      title: {
        text: 'Driver Importance',
        style: {
          fontSize: '1rem',
          fontFamily: 'Inter',
          fontWeight: 300,
          color: chakraColorToCSS('gray.800'),
        },
      },
      // show: false,
      floating: false,
      min: yRange[0],
      max: yRange[1],
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 45,
        right: 45,
        bottom: 45,
        left: 45,
      },
    },
  }
}

export default function QuadMap({ results = {}, ...props }) {
  // const yMedian = Math.round(median(themes.map(({ importance }) => importance)))
  // const xMedian = Math.round(
  //   median(themes.map(({ industryScore }) => industryScore))
  // )
  const url = new URL(window.location.href)
  const type = getTypeFromParams(url.searchParams)
  const themeImportance =
    type === 'Media' ? mediaThemeImportance : techThemeImportance
  const medianThemeImportance =
    type === 'Media' ? mediaMedianThemeImportance : techMedianThemeImportance
  const medianThemePerformance =
    type === 'Media' ? mediaMedianThemePerformance : techMedianThemePerformance
  const attributeThemeColors =
    type === 'Media' ? mediaAttributeThemeColors : techAttributeThemeColors
  const yMedian = medianThemeImportance
  const xMedian = medianThemePerformance
  const xRange = [Math.floor(Math.min(20, ...Object.values(results))), 100]
  const yRange = [0, 30]
  const colors = Object.values(attributeThemeColors).map(chakraColorToCSS)
  const [options, setOptions] = React.useState(
    getChartOptions(xRange, xMedian, yRange, yMedian, colors, type)
  )
  React.useEffect(() => {
    const xRange = [Math.floor(Math.min(20, ...Object.values(results))), 100]
    setOptions(prev => ({
      ...prev,
      ...getChartOptions(xRange, xMedian, yRange, yMedian, colors),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const isPrint = url.searchParams.get('print') === 'true'
  // const [isPrint] = useMediaQuery('print')
  // const chartWidth = isPrint ? '800px' : '100%'
  const chartWidth = isPrint ? '1100px' : '100%'
  return (
    <Stack direction={'column'} p={4} {...props}>
      <Heading as={'h3'} size={'lg'} color={'gray.800'} mb={'-6'}>
        Theme Performance + Importance
      </Heading>
      <Chart
        options={options}
        width={chartWidth}
        series={[
          {
            name: 'Importance',
            data: Object.keys(results).map(theme => [
              Math.round(results[theme]),
              Math.round(
                themeImportance.find(themeEntry => themeEntry.theme === theme)
                  .importance
              ),
            ]),
          },
        ]}
        type="scatter"
        // height={350}
      />
    </Stack>
  )
}
