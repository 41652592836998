import {
  Stack,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React from 'react'
import { mediaAttributeThemeColors } from '../MediaScorecardData'
import { techAttributeThemeColors } from '../techScorecardData'
import { getTypeFromParams } from '../utils/getFromParams'
import { IoIosStar, IoIosStarOutline } from 'react-icons/all'

export default function ActionItems({ actionItems, ...props }) {
  const url = new URL(window.location.href)
  const type = getTypeFromParams(url.searchParams)
  const attributeThemeColors =
    type === 'Media' ? mediaAttributeThemeColors : techAttributeThemeColors
  return (
    <Stack direction={'column'} p={4} {...props}>
      <Heading as={'h3'} size={'lg'} color={'grey.800'} mb={'3'}>
        Action Items
      </Heading>
      <Table
        mt={7}
        fontSize={'md'}
        sx={{ '@media print': { width: '800px !important' } }}
      >
        <Thead>
          <Tr
            sx={{
              '& th': {
                fontWeight: 'semibold',
                fontSize: '11.25pt',
                letterSpacing: 'wide',
                fontFamily: 'Inter',
                // color: 'blackAlpha.700',
                color: 'gray.800',
              },
            }}
          >
            <Th>Theme</Th>
            <Th isNumeric>Statement</Th>
            <Th>Impact of Improvement</Th>
          </Tr>
        </Thead>
        <Tbody>
          {actionItems.slice(0, 3).map(({ theme, statement, impact }, i) => {
            const impactText =
              impact > 35 ? (
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  display={'inline'}
                  color={'black'}
                >
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                </Stack>
              ) : impact > 17.5 ? (
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  display={'inline'}
                  color={'gray.800'}
                >
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStarOutline opacity={0.5} />
                </Stack>
              ) : (
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  display={'inline'}
                  color={'grey.800'}
                >
                  <IoIosStar />
                  <IoIosStarOutline opacity={0.5} />
                  <IoIosStarOutline opacity={0.5} />
                </Stack>
              )
            return (
              <Tr
                key={i}
                // sx={{
                //   '& td': {
                //     ...(impactText === 'High'
                //       ? { fontWeight: 'black' }
                //       : impactText === 'Low'
                //       ? { color: 'blackAlpha.500' }
                //       : {}),
                //   },
                // }}
              >
                {/* <Td color={attributeThemeColors[theme]}>{theme}</Td> */}
                <Td>{theme}</Td>
                <Td
                  isNumeric
                  minWidth={{ xs: '200px', sm: '350px', xl: '500px' }}
                >
                  {statement}
                </Td>
                <Td
                  // fontWeight={impactText === 'High' ? 'black' : 'normal'}
                  sx={{
                    '& svg': {
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      boxSize: '1.2em',
                    },
                  }}
                >
                  {impactText}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Stack>
  )
}
