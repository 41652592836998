import { useLocation } from 'react-router-dom'
import { Flex, Link, useColorModeValue } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

export default function Pagination({
  page,
  perPage,
  numItemsOnCurrentPage,
  totalItems,
  next,
  prev,
  children,
  ...props
}) {
  const startingItem = (page - 1) * perPage + 1
  const endingItem = startingItem + numItemsOnCurrentPage - 1
  const location = useLocation()
  const color = useColorModeValue('gray.700', 'gray.100')
  return (
    <Flex
      textAlign={'right'}
      // left={'50vw'}
      color={color}
      flexWrap={'nowrap'}
      justifyItems={'center'}
      align={'center'}
      {...props}
    >
      {children}
      {prev ? (
        <Link
          mr={1}
          as={RouterLink}
          to={location.pathname + '?' + prev.toString()}
        >
          {'<'}
        </Link>
      ) : null}
      {`${startingItem}-${endingItem} of ${totalItems}`}
      {next ? (
        <Link
          colorScheme={'purple'}
          ml={1}
          as={RouterLink}
          to={location.pathname + '?' + next.toString()}
        >
          {'>'}
        </Link>
      ) : null}
    </Flex>
  )
}
