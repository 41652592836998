import { Box, Stack, Text } from '@chakra-ui/react'
import PerformanceScoringTable from './PerformanceScoringTable'
import React from 'react'

export default function Scorecard({ handleScoreChange, scores, demoMode }) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakAfter: 'always',
        },
      }}
    >
      <Box w={'full'} my={9}>
        <Stack spacing={12}>
          <Text
            fontSize={'xl'}
            color="gray.700"
            px={4}
            textAlign={'left'}
            // maxW={800}
          >
            Please rate your company on how well it performs
            <br /> when customers get information, make a purchase, or resolve
            an issue.
            {/*Please rate your company on how well it performs on the*/}
            {/*following specific characteristics when customers interact with*/}
            {/*your company to get information, make a purchase, or resolve an*/}
            {/*issue.*/}
          </Text>
          <PerformanceScoringTable
            handleScoreChange={handleScoreChange}
            scores={scores}
          />
        </Stack>
      </Box>
    </Box>
  )
}
