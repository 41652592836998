import React from 'react'
// import {
//   Box,
//   Text,
//   Stack,
//   FormControl,
//   Input,
//   Alert,
//   AlertIcon,
//   AlertDescription,
// } from '@chakra-ui/react'
// import { ArrowForwardIcon } from '@chakra-ui/icons'
// import LinkButton from './components/LinkButton'
import { getTypeFromParams } from './utils/getFromParams'
import ScorecardApp from './ScorecardApp'
import Navbar from './components/Navbar'

// const invalidEmailMessage = 'Please enter a valid email address'

export default function UnprotectedApp() {
  const url = new URL(window.location.href)
  const type = getTypeFromParams(url.searchParams)
  const activateDemoMode =
    url.searchParams.get('demo') === 'true' &&
    url.searchParams.get('email') === null
  if (activateDemoMode) {
    window.location.href = `${url.pathname}?demo=true&email=demo@actioniq.com&type=${type}`
  }
  // const email = getEmailFromParams(url.searchParams)
  //
  // const emailInput = React.useRef()
  // const [error, setError] = React.useState('')
  // const [validEmail, setValidEmail] = React.useState(false)
  // const [emailValue, setEmailValue] = React.useState('')
  //
  // const handleChange = ev => {
  //   // if (sent) {
  //   //   return
  //   // }
  //   setValidEmail(prevValidEmail => {
  //     const currValid = emailRegex.test(emailInput.current.value)
  //     if (currValid && !prevValidEmail) {
  //       if (error === invalidEmailMessage) {
  //         setError('')
  //       }
  //     }
  //     return currValid
  //   })
  //   if (ev.type === 'keyup' && ev.key !== 'Enter') {
  //     return
  //   } else if (
  //     emailInput.current.value.length === 0 ||
  //     !emailRegex.test(emailInput.current.value)
  //   ) {
  //     setError(invalidEmailMessage)
  //   } else {
  //     window.location.href = `${url.pathname}?type=${type}&email=${emailInput.current.value}`
  //     setError('')
  //   }
  // }
  // const validEmailColor = 'green.400'

  return (
    <>
      <Navbar />
      <ScorecardApp />
      {/*{email !== null ? (*/}
      {/*  <ScorecardApp />*/}
      {/*) : (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    flexDirection="column"*/}
      {/*    alignItems="center"*/}
      {/*    justifyContent="center"*/}
      {/*    height="calc(100vh - 78.25px - 60px)"*/}
      {/*  >*/}
      {/*    <Stack*/}
      {/*      // boxShadow={'2xl'}*/}
      {/*      border={'1px solid'}*/}
      {/*      borderColor={'gray.200'}*/}
      {/*      // bg={'gray.50'}*/}
      {/*      // rounded={'xl'}*/}
      {/*      p={10}*/}
      {/*      spacing={8}*/}
      {/*      align={'center'}*/}
      {/*      minWidth={{ base: 'xs', md: 'sm' }}*/}
      {/*    >*/}
      {/*      <Text fontSize={'lg'} color={'gray.600'}>*/}
      {/*        Enter your email address to get started*/}
      {/*      </Text>*/}
      {/*      <Stack*/}
      {/*        spacing={5}*/}
      {/*        // direction={{ base: 'column', md: 'row' }}*/}
      {/*        w={'full'}*/}
      {/*        as={'form'}*/}
      {/*        noValidate*/}
      {/*        onSubmit={e => {*/}
      {/*          e.preventDefault()*/}
      {/*          handleChange(e)*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <FormControl id={'email'} isRequired>*/}
      {/*          <Input*/}
      {/*            placeholder={'your-email@example.com'}*/}
      {/*            type={'email'}*/}
      {/*            autoComplete={'email'}*/}
      {/*            autoFocus*/}
      {/*            ref={emailInput}*/}
      {/*            onKeyUp={handleChange}*/}
      {/*            value={emailValue}*/}
      {/*            onChange={ev => setEmailValue(ev.target.value)}*/}
      {/*            isRequired*/}
      {/*            color={'gray.800'}*/}
      {/*            border="1px solid"*/}
      {/*            borderColor="gray.200"*/}
      {/*            _focus={{*/}
      {/*              bg: 'gray.200',*/}
      {/*              ...(validEmail*/}
      {/*                ? { border: '2px solid', borderColor: validEmailColor }*/}
      {/*                : {}),*/}
      {/*            }}*/}
      {/*          />*/}
      {/*        </FormControl>*/}
      {/*        <LinkButton*/}
      {/*          onClick={ev => {*/}
      {/*            if (!validEmail) {*/}
      {/*              ev.preventDefault()*/}
      {/*              handleChange(ev)*/}
      {/*            }*/}
      {/*          }}*/}
      {/*          textAlign={'center'}*/}
      {/*          href={*/}
      {/*            validEmail && emailInput.current*/}
      {/*              ? `${url.pathname}?type=${type}&email=${emailInput.current.value}`*/}
      {/*              : url.pathname*/}
      {/*          }*/}
      {/*        >*/}
      {/*          <Text as={'span'}>*/}
      {/*            Go To Scorecard*/}
      {/*            <ArrowForwardIcon ml={4} w={6} h={5} verticalAlign={'sub'} />*/}
      {/*          </Text>*/}
      {/*        </LinkButton>*/}
      {/*        {error !== '' ? (*/}
      {/*          <Alert*/}
      {/*            status="error"*/}
      {/*            flexDirection="column"*/}
      {/*            alignItems="center"*/}
      {/*            justifyContent="center"*/}
      {/*            textAlign="center"*/}
      {/*            p={5}*/}
      {/*          >*/}
      {/*            <AlertIcon boxSize="32px" mr={0} mb={4} />*/}
      {/*            <AlertDescription maxWidth="xs">{error}</AlertDescription>*/}
      {/*          </Alert>*/}
      {/*        ) : null}*/}
      {/*      </Stack>*/}
      {/*    </Stack>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </>
  )
}
