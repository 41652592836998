import React from 'react'
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Td,
  Text,
} from '@chakra-ui/react'

export default function ScoreSlider({
  score,
  handleChange,
  statementIndex,
  bgcolor,
  ...props
}) {
  const url = new URL(window.location.href)
  const id = url.searchParams.get('id')
  const lighterColor = bgcolor
  // const lighterColor =
  //   bgcolor.split('.')[0] + '.' + (parseInt(bgcolor.split('.')[1], 10) - 100)
  return (
    <Td isNumeric {...props}>
      <Text
        fontSize={'lg'}
        display={id === null ? 'none' : 'block'}
        sx={{ '@media print': { display: 'block' } }}
      >
        {score}%
      </Text>
      <Slider
        id={`slider-${statementIndex}`}
        display={id === null ? 'block' : 'none'}
        sx={{ '@media print': { display: 'none' } }}
        value={score}
        min={0}
        max={100}
        onChange={s => handleChange(s)}
        onChangeStart={() => {
          // handleOpenTooltip();
        }}
        _hover={{ '& .chakra-slider__marker': { display: 'block' } }}
      >
        {/*<SliderMark*/}
        {/*  value={100}*/}
        {/*  mt="1"*/}
        {/*  ml="-2.5"*/}
        {/*  fontSize="xs"*/}
        {/*  color={'gray.500'}*/}
        {/*>*/}
        {/*  100*/}
        {/*</SliderMark>*/}
        {/*<SliderMark*/}
        {/*  value={score}*/}
        {/*  textAlign="center"*/}
        {/*  bg="brand.500"*/}
        {/*  color="white"*/}
        {/*  mt="-9"*/}
        {/*  borderRadius="sm"*/}
        {/*  ml="-5"*/}
        {/*  w="12"*/}
        {/*  display={isDragging ? null : 'none'}*/}
        {/*>*/}
        {/*  {score}%*/}
        {/*</SliderMark>*/}
        <SliderTrack>
          {/*<SliderFilledTrack bg={'brand.500'} />*/}
          <SliderFilledTrack opacity={0.6} bg={lighterColor} />
        </SliderTrack>
        <SliderThumb
          boxSize={7}
          fontSize={'xs'}
          whiteSpace={'nowrap'}
          color={'gray.800'}
          bg={'white'}
          fontWeight={'semibold'}
          // fontFamily={'monospace'}
        >
          {score}
        </SliderThumb>
        {/*</Tooltip>*/}
        {/*<SliderThumb />*/}
      </Slider>
    </Td>
  )
}
