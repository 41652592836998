// import { mode } from '@chakra-ui/theme-tools'
import { extendTheme } from '@chakra-ui/react'
// import { selectAnatomy as parts } from '@chakra-ui/anatomy'

const fonts = {
  body: 'Inter',
  heading: 'DM Serif Display',
}

const styles = {
  global: props => ({
    '@media screen and (min-width: 25em)': {
      html: {
        fontSize: 'calc( 10px + (16 - 10) * (100vw - 400px) / (1440 - 400) )',
      },
    },
    '@media screen and (min-width: 50em)': {
      html: {
        fontSize: 'calc( 10px + (16 - 10) * (100vw - 400px) / (1440 - 400) )',
      },
    },
    [`@media print`]: {
      body: {
        width: '700px !important',
        '& .no-print': {
          display: 'none',
        },
      },
      // '@page': {
      //   size: 'landscape',
      // },
    },
    body: {
      // color: mode('gray.800', 'whiteAlpha.900')(props),
      // bg: mode('gray.100', 'gray.800')(props),
      '@media screen': {
        bg: '#F5F5F5',
        paddingTop: '78.25px',
      },
      paddingBottom: '60px',
    },
  }),
}

const colors = {
  pink: {
    500: '#FFC5B2',
  },
  yellow: {
    500: '#D5D4C1',
  },
  green: {
    // 400: '#8CEC8A',
    500: '#C5F9AF',
    600: '#8DED8A',
  },
  purple: {
    500: '#E6D6FC',
  },
  blue: {
    500: '#C2F0FB',
    600: '#95DDFB',
  },
  brand: {
    500: '#141023',
  },
  action: {
    500: '#B9F9A0',
    600: '#8DED8A',
  },
  error: {
    500: '#E98565',
  },
  gray: {
    300: 'rgb(215,215,215)',
    800: '#141023',
  },
  bg: {
    500: '#F5F5F5',
  },
}
// const baseStyleField = props => {
//   return {
//     ...Input.baseStyle.field,
//     bg: mode('gray.100', 'gray.700')(props),
//     appearance: 'none',
//     paddingBottom: '1px',
//     lineHeight: 'normal',
//     '> option, > optgroup': {
//       bg: mode('gray.100', 'gray.700')(props),
//     },
//   }
// }
//
// const baseStyleIcon = {
//   width: '1.5rem',
//   height: '100%',
//   insetEnd: '0.5rem',
//   position: 'relative',
//   color: 'currentColor',
//   fontSize: '1.25rem',
//   _disabled: {
//     opacity: 0.5,
//   },
// }

// const components = {
// Drawer: {
//   // setup light/dark mode component defaults
//   baseStyle: props => ({
//     dialog: {
//       bg: mode('white', '#141214')(props),
//     },
//   }),
// },
// }

export const theme = extendTheme({ styles, fonts, colors })
