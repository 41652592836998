export function titleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function toInt(str) {
  return ['string', 'number'].includes(typeof str)
    ? parseInt(str, 10)
    : undefined
}
