import { Box, Flex, Icon, Stack, Text, useToast } from '@chakra-ui/react'
import Scorecard from './components/Scorecard'
import Results from './components/Results'
import LinkButton from './components/LinkButton'
import {
  // ArrowBackIcon,
  // ArrowForwardIcon,
  DownloadIcon,
  LinkIcon,
} from '@chakra-ui/icons'
import React from 'react'
import fromEntries from './utils/fromEntries'
import {
  mediaAttributes,
  mediaMaxAttributeImportance,
  mediaThemeIndustryPerformance,
} from './MediaScorecardData'
import {
  techAttributes,
  techMaxAttributeImportance,
  techThemeIndustryPerformance,
} from './techScorecardData'
import { groupBy, mapValues, meanBy } from 'lodash'
import copy from 'copy-to-clipboard'
import getEmailFromParams, { getTypeFromParams } from './utils/getFromParams'
import RequestEmailModal from './components/RequestEmailModal'
import { CgArrowLongRight } from 'react-icons/cg'

function getScoresFromParams(params) {
  const type = getTypeFromParams(params)
  const scores = params.get('scores')
    ? params.get('scores').split(',').map(Number)
    : undefined
  const attributes = type === 'Media' ? mediaAttributes : techAttributes
  if (
    !scores ||
    scores.length !== attributes.length ||
    scores.some(score => score < 0 || score > 100)
  )
    return undefined
  return fromEntries(
    attributes.map((a, i) => [
      a.statement,
      {
        score: scores[i],
        theme: a.theme,
        statement: a.statement,
      },
    ])
  )
}
export default function ScorecardApp() {
  const url = new URL(window.location.href)
  const scoresFromParams = getScoresFromParams(url.searchParams)
  const demoMode = url.searchParams.get('demo') === 'true'
  const scorecardId = url.searchParams.get('id')
  const showResults = url.searchParams.get('results') === 'true'
  const email = getEmailFromParams(url.searchParams)
  const type = getTypeFromParams(url.searchParams)
  const attributes = type === 'Media' ? mediaAttributes : techAttributes
  const maxAttributeImportance =
    type === 'Media' ? mediaMaxAttributeImportance : techMaxAttributeImportance
  const themeIndustryPerformance =
    type === 'Media'
      ? mediaThemeIndustryPerformance
      : techThemeIndustryPerformance
  const [scores, setScores] = React.useState(
    scoresFromParams
      ? scoresFromParams
      : fromEntries(
          attributes.map(a => [
            a.statement,
            {
              score: Math.round(a.industryScore),
              theme: a.theme,
              statement: a.statement,
            },
          ])
        )
  )
  const calculateResult = scores => {
    return mapValues(groupBy(scores, 'theme'), statements =>
      // Math.round(meanBy(statements, 'score'))
      meanBy(statements, 'score')
    )
  }
  const [results, setResults] = React.useState(
    scoresFromParams
      ? calculateResult(scoresFromParams)
      : mapValues(themeIndustryPerformance, Math.round)
  )
  const handleScoreChange = (statement, value) => {
    const newScores = {
      ...scores,
      [statement]: { ...scores[statement], score: value },
    }
    const newResults = calculateResult(newScores)
    setResults(newResults)
    setScores(newScores)
  }

  // const actionItems = attributesOfImportantThemes
  //   .filter(({ theme }) => results[theme] < medianThemePerformance)
  const actionItems = attributes
    //   .filter(({ theme }) => results[theme] < medianThemePerformance)
    // .filter(
    //   ({ stdScore, statement }) =>
    //     attributes.find(a => a.statement === statement).industryScore +
    //       0.674 * stdScore >
    //     scores[statement].score
    // )
    .map(({ statement, importance, stdScore, theme }) => ({
      statement,
      importance,
      // stdScore,
      theme,
      score: scores[statement].score,
      // stdDevOfScore:
      //   (scores[statement].score -
      //     attributes.find(a => a.statement === statement).industryScore) /
      //   stdScore,
      // impact:
      //   100 *
      //   ((((100 - scores[statement].score) / 100) * importance) /
      //     maxAttributeImportance),
      impact:
        100 *
        (((100 - scores[statement].score) / 100) *
          (importance / maxAttributeImportance)),
    }))
    .sort((a, b) => b.impact - a.impact)
  // .slice(0, 3)
  // console.log({ attributeImpactScores: actionItems })
  // const handlePostScorecard = () =>
  //   apiFetch('/api/scorecard', undefined, undefined, {
  //     method: 'POST',
  //     data: {
  //       email,
  //       type: 'Media',
  //       score: Object.values(scoresFromParams)
  //         .map(({ score }) => score)
  //         .join(','),
  //     },
  //   })
  //     .then(data => {
  //       console.log({ data })
  //       // setScorecardId(data.id)
  //     })
  //     .catch(err => console.log({ err }))

  const toast = useToast()
  const [linkCopied, setLinkCopied] = React.useState(false)
  const handleCopyShareLink = () => {
    const { origin } = window.location
    const url = `${origin}/api/scorecard?id=${scorecardId}&share=true`
    const copied = copy(url)
    if (copied) {
      setLinkCopied(true)
      setTimeout(() => setLinkCopied(false), 1500)
      toast({
        title: 'Share Link copied to clipboard',
        variant: 'subtle',
        status: 'success',
        duration: 1500,
        isClosable: false,
        position: 'bottom-right',
      })
    } else {
      toast({
        title: 'Unable to copy link.',
        description:
          'Usually this is an issue with your browser. Try restarting or using a different browser.',
        variant: 'subtle',
        status: 'error',
        duration: 3000,
        isClosable: false,
        position: 'bottom-right',
      })
    }
  }

  const [emailModalIsOpen, setEmailModalIsOpen] = React.useState(false)

  return (
    <>
      <RequestEmailModal
        isOpen={emailModalIsOpen}
        onClose={() => setEmailModalIsOpen(false)}
        scores={scores}
      />
      <Box maxW={'container.xl'} mx={'auto'} p={12}>
        {demoMode || !showResults ? (
          <Scorecard
            handleScoreChange={handleScoreChange}
            scores={scores}
            demoMode={demoMode}
          />
        ) : null}
        {
          demoMode || (scoresFromParams && showResults) ? (
            <Results actionItems={actionItems} results={results} />
          ) : !email && !scorecardId ? (
            <LinkButton
              as={'button'}
              float={'right'}
              onClick={() => setEmailModalIsOpen(true)}
            >
              <Text as={'span'}>
                View Results
                <Icon
                  as={CgArrowLongRight}
                  // transform="rotate(180deg)"
                  ml={4}
                  w={6}
                  h={5}
                  display="inline"
                />
              </Text>
            </LinkButton>
          ) : (
            <LinkButton
              href={
                scores
                  ? `/api/scorecard?type=${type}&email=${email}&scores=${Object.values(
                      scores
                    )
                      .map(({ score }) => score)
                      .join(',')}&results=true`
                  : '#'
              }
              size="lg"
              float={'right'}
            >
              <Text as={'span'}>
                {scorecardId === null ? 'Get Results' : 'View Results'}
                {/* <ArrowForwardIcon ml={4} w={6} h={5} /> */}
                <Icon
                  as={CgArrowLongRight}
                  // transform="rotate(180deg)"
                  ml={4}
                  w={6}
                  h={5}
                  display="inline"
                />
              </Text>
            </LinkButton>
          )

          // <LinkButton>
          //   href={
          //     scorecardId
          //       ? `/api/scorecard?id=${scorecardId}&results=true`
          //       : scores
          //       ? `/api/scorecard?type=${type}&email=${email}&scores=${Object.values(
          //           scores
          //         )
          //           .map(({ score }) => score)
          //           .join(',')}&results=true`
          //       : '#'
          //   }
          //   size="lg"
          //   float={'right'}
          // >
          //   <Text as={'span'}>
          //     {scorecardId === null ? 'Get Results' : 'View Results'}
          //     <ArrowForwardIcon ml={4} w={6} h={5} />
          //   </Text>
          // </LinkButton>
        }
        {!demoMode && showResults ? (
          <Flex mt={5} w={'full'} justifyContent={'space-between'}>
            <LinkButton
              variant={'secondary'}
              href={
                scorecardId
                  ? `/api/scorecard?id=${scorecardId}&results=false`
                  : scores
                  ? `/api/scorecard?type=${type}&email=${email}&scores=${Object.values(
                      scores
                    )
                      .map(({ score }) => score)
                      .join(',')}&results=false`
                  : '#'
              }
              float={'right'}
            >
              <Text as={'span'}>
                <Icon
                  as={CgArrowLongRight}
                  transform="rotate(180deg)"
                  ml={4}
                  w={6}
                  h={5}
                  display="inline"
                />
                {/* <ArrowBackIcon mr={4} w={6} h={5} /> */}
                Back to Scores
              </Text>
            </LinkButton>
            <Stack direction={'row'}>
              <LinkButton
                href={`/api/scorecard_dl/${scorecardId ?? ''}`}
                isExternal
              >
                <Text as={'span'}>
                  Download
                  <DownloadIcon ml={4} h={5} />
                </Text>
              </LinkButton>
              <LinkButton
                as={'button'}
                // href={`/api/scorecard_dl/${scorecardId ?? ''}`}
                onClick={handleCopyShareLink}
                disabled={linkCopied}
              >
                <Text as={'span'}>
                  Share
                  <LinkIcon ml={4} h={5} />
                </Text>
              </LinkButton>
            </Stack>
          </Flex>
        ) : null}
      </Box>
    </>
  )
}
