import { Link, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import getEmailFromParams from '../utils/getFromParams'

export default function Footer() {
  const url = new URL(window.location.href)
  const printMode = url.searchParams.get('print') === 'true'
  const email = getEmailFromParams(url.searchParams)
  const id = url.searchParams.get('id')
  return (
    <Stack
      position={'fixed'}
      right={2}
      bottom={0}
      color={'gray.500'}
      fontSize={'xs'}
      direction={'row'}
      display={'inline-block'}
      spacing={6}
      bg={'bg.500'}
      zIndex={2}
      borderRadius={'lg'}
      p={1}
      backdropFilter={'blur(5px) saturate(100%)'}
      sx={{
        '@media print': {
          bg: 'white',
          'a::after': { content: '" - " attr(href) ""' },
          '& a': {
            textDecoration: 'none',
          },
        },
      }}
    >
      <Text display={'inline'}>
        Copyright © {new Date().getFullYear()}{' '}
        <Link
          color="inherit"
          href="https://www.actioniq.com/cx-iq/"
          target="_blank"
          textDecoration={'underline'}
        >
          ActionIQ
        </Link>
      </Text>
      <Text as={'span'}>
        Powered by{' '}
        <Link
          color="inherit"
          href="https://radiusinsights.com/"
          target="_blank"
          textDecoration={'underline'}
        >
          Radius Labs
        </Link>
      </Text>
      {printMode ? (
        <Text as={'span'}>
          Prepared for {email} - <small>ID: {id}</small>
        </Text>
      ) : null}
    </Stack>
  )
}
