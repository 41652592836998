import {
  Box,
  Heading,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToken,
} from '@chakra-ui/react'
import {
  mediaAttributeThemeColors,
  mediaThemeIndustryPerformance,
} from '../MediaScorecardData'
import {
  techAttributeThemeColors,
  techThemeIndustryPerformance,
} from '../techScorecardData'
import Databar from './Databar'
import { Logo } from '../Logo'
import React from 'react'
import { getTypeFromParams } from '../utils/getFromParams'

export default function ThemePerformanceTable({ results, ...props }) {
  const url = new URL(window.location.href)
  const type = getTypeFromParams(url.searchParams)
  const themeIndustryPerformance =
    type === 'Media'
      ? mediaThemeIndustryPerformance
      : techThemeIndustryPerformance
  const attributeThemeColors =
    type === 'Media' ? mediaAttributeThemeColors : techAttributeThemeColors
  const positiveColor = useToken('colors', 'green.500')
  const negativeColor = useToken('colors', 'red.500')

  const performanceGaps = Object.keys(results).map(
    theme => results[theme] - themeIndustryPerformance[theme]
  )
  const barMax = Math.ceil(
    Math.max(...performanceGaps.map(gap => Math.abs(gap)))
  )
  return (
    <Box {...props}>
      <Heading as={'h3'} size={'lg'} color={'grey.800'} mb={'3'}>
        Theme Performance
      </Heading>
      <Table>
        <Thead>
          <Tr
            sx={{
              '& th': {
                fontWeight: 'semibold',
                fontSize: '11.25pt',
                letterSpacing: 'wide',
                fontFamily: 'Inter',
                // color: 'blackAlpha.700',
                color: 'gray.800',
              },
              '@media print': {
                '& th': {
                  fontSize: 'xs',
                },
              },
            }}
          >
            <Th>Theme</Th>
            <Th>Performance Score Input</Th>
            <Th>Industry Performance Score</Th>
            <Th colSpan={1} whiteSpace={'nowrap'}>
              Performance Gap
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {Object.keys(results).map(theme => {
            const industryPerformance = themeIndustryPerformance[theme]
            const performanceGap = results[theme] - industryPerformance
            return (
              <Tr key={theme} sx={{ '& td': { textAlign: 'center' } }}>
                {/* <Td color={attributeThemeColors[theme]}>{theme}</Td> */}
                <Td>{theme}</Td>
                <Td
                // isNumeric
                >
                  {Math.round(results[theme])}
                </Td>
                <Td
                  // isNumeric
                  color={'gray.500'}
                >{`${industryPerformance.toFixed(0)}`}</Td>
                {/*<Td isNumeric>{`${performanceGap.toFixed(0)}%`}</Td>*/}
                <Td width={'100px'} p={0}>
                  <Databar
                    value={Math.round(performanceGap)}
                    positiveColor={positiveColor}
                    negativeColor={negativeColor}
                    max={barMax}
                  />
                </Td>
                <Td isNumeric>
                  <Text
                    color={
                      'gray.800'
                      // Math.round(performanceGap) === 0
                      //   ? 'gray.500'
                      //   : performanceGap < 0
                      //   ? negativeColor
                      //   : positiveColor
                    }
                  >
                    {Math.round(performanceGap)}
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
        <TableCaption position={'bottom'}>
          <Logo
            float={'right'}
            display={'none'}
            h={'25px'}
            sx={{ '@media print': { display: 'block' } }}
            transform={'translate(100%, -85%)'}
          />
        </TableCaption>
      </Table>
    </Box>
  )
}
