import {
  Alert,
  AlertDescription,
  AlertIcon,
  FormControl,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import LinkButton from './LinkButton'
// import { ArrowForwardIcon } from '@chakra-ui/icons'
import React from 'react'
import { getTypeFromParams } from '../utils/getFromParams'
import { emailRegex } from '../utils/regex'
import { CgArrowLongRight } from 'react-icons/cg'

const invalidEmailMessage = 'Please enter a valid email address'

export default function RequestEmailModal({ isOpen, onClose, scores }) {
  const url = new URL(window.location.href)
  const type = getTypeFromParams(url.searchParams)
  // const email = getEmailFromParams(url.searchParams)

  const emailInput = React.useRef()
  const [error, setError] = React.useState('')
  // const [sent, setSent] = React.useState(false)
  const [validEmail, setValidEmail] = React.useState(false)
  const [emailValue, setEmailValue] = React.useState('')

  const handleChange = ev => {
    // if (sent) {
    //   return
    // }
    setValidEmail(prevValidEmail => {
      const currValid = emailRegex.test(emailInput.current.value)
      if (currValid && !prevValidEmail) {
        if (error === invalidEmailMessage) {
          setError('')
        }
      }
      return currValid
    })
    if (ev.type === 'keyup' && ev.key !== 'Enter') {
      return
    } else if (
      emailInput.current.value.length === 0 ||
      !emailRegex.test(emailInput.current.value)
    ) {
      setError(invalidEmailMessage)
    } else {
      // window.location.href = `${url.pathname}?type=${type}&email=${emailInput.current.value}`
      window.location.href = `/api/scorecard?type=${type}&email=${
        emailInput.current.value
      }&scores=${Object.values(scores)
        .map(({ score }) => score)
        .join(',')}&results=true`
      // console.log('REQUEST LINK')
      setError('')
      // requestMagicLink(emailInput.current.value).catch(({ message }) => {
      //   console.log(message)
      // })
    }
  }
  const validEmailColor = 'green.400'
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
      <ModalOverlay backdropFilter={'blur(10px) saturate(100%)'} />
      <ModalContent>
        <ModalHeader>Enter your email</ModalHeader>
        <ModalCloseButton />
        {/*<Box*/}
        {/*  display="flex"*/}
        {/*  flexDirection="column"*/}
        {/*  alignItems="center"*/}
        {/*  justifyContent="center"*/}
        {/*  height="calc(100vh - 78.25px - 60px)"*/}
        {/*>*/}
        <ModalBody>
          <Stack
            // boxShadow={'2xl'}
            // border={'1px solid'}
            borderColor={'gray.200'}
            // bg={'gray.50'}
            // rounded={'xl'}
            p={10}
            spacing={8}
            align={'center'}
            minWidth={{ base: 'xs', md: 'sm' }}
          >
            <Text fontSize={'lg'} color={'gray.600'}>
              Enter your email address to view your results
            </Text>
            <Stack
              spacing={5}
              // direction={{ base: 'column', md: 'row' }}
              w={'full'}
              as={'form'}
              noValidate
              onSubmit={e => {
                e.preventDefault()
                handleChange(e)
              }}
            >
              <FormControl id={'email'} isRequired>
                <Input
                  placeholder={'your-email@example.com'}
                  type={'email'}
                  autoComplete={'email'}
                  autoFocus
                  ref={emailInput}
                  onKeyUp={handleChange}
                  value={emailValue}
                  onChange={ev => setEmailValue(ev.target.value)}
                  isRequired
                  color={'gray.800'}
                  border="1px solid"
                  borderColor="gray.200"
                  _focus={{
                    bg: 'gray.200',
                    ...(validEmail
                      ? { border: '2px solid', borderColor: validEmailColor }
                      : {}),
                  }}
                />
              </FormControl>
              <LinkButton
                onClick={ev => {
                  if (!validEmail) {
                    ev.preventDefault()
                    handleChange(ev)
                  }
                }}
                textAlign={'center'}
                // href={`/api/scorecard?type=${type}&email=${email}&scores=${Object.values(
                href={`/api/scorecard?type=${type}&email=${
                  validEmail ? emailValue : 'INVALIDEMAIL'
                }&scores=${Object.values(scores)
                  .map(({ score }) => score)
                  .join(',')}&results=true`}
              >
                <Text as={'span'}>
                  View Results
                  {/* <ArrowForwardIcon ml={4} w={6} h={5} verticalAlign={'sub'} /> */}
                  <Icon
                    as={CgArrowLongRight}
                    // transform="rotate(180deg)"
                    ml={4}
                    w={6}
                    h={5}
                    display="inline"
                  />
                </Text>
              </LinkButton>
              {error !== '' ? (
                <Alert
                  status="error"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  p={5}
                >
                  <AlertIcon boxSize="32px" mr={0} mb={4} />
                  <AlertDescription maxWidth="xs">{error}</AlertDescription>
                </Alert>
              ) : null}
            </Stack>
          </Stack>
          {/*</Box>*/}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
