import { Button, Heading, Stack } from '@chakra-ui/react'
import React from 'react'
import QuadMap from './QuadMap'
import ActionItems from './ActionItems'

// import html2pdf from 'html2pdf.js'
import { DownloadIcon } from '@chakra-ui/icons'
import ThemePerformanceTable from './ThemePerformanceTable'

export default function Results({ actionItems, results }) {
  const ref = React.useRef()
  return (
    <Stack ref={ref} spacing={16}>
      <Stack direction={'row'} spacing={6}>
        <Heading as="h1" size="xl">
          Results
        </Heading>
        <Button
          display={'none'}
          variant={'ghost'}
          className={'no-print'}
          leftIcon={<DownloadIcon />}
          // onClick={() =>
          //   html2pdf(ref.current, {
          //     pagebreak: { after: '#gaptable' },
          //     // image: { type: 'jpg', quality: 0.95 },
          //     html2canvas: {
          //       scale: 4,
          //       // scrollX: -window.scrollX,
          //       // scrollY: -window.scrollY,
          //       // windowWidth: document.documentElement.offsetWidth,
          //       // windowHeight: document.documentElement.offsetHeight,
          //     },
          //     jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
          //   })
          // }
        >
          Download PDF
        </Button>
      </Stack>
      <Stack
        sx={{
          '& > div': {
            '@media print': {
              pageBreakBefore: 'always',
              pageBreakAfter: 'always',
              height: '142vh',
            },
          },
        }}
      >
        <ThemePerformanceTable results={results} />
        <QuadMap
          results={results}
          sx={{
            '@media print': {
              transform: 'rotate(270deg) translate(-286mm, 0)',
              transformOrigin: '0 0',
              // width: '276mm',
              // height: '190mm',
              // height: '142vh',
            },
          }}
        />
        <ActionItems actionItems={actionItems} />
      </Stack>
    </Stack>
  )
}
