import React from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/all'
import { EmailIcon } from '@chakra-ui/icons'
import { emailRegex } from './utils/regex'
import { useLocation } from 'react-router-dom'
import { Logo } from '../../Logo'

const invalidEmailMessage = 'Please enter a valid email'

export default function MagicLinkForm({ message, setMessage }) {
  const emailInput = React.useRef()
  const [error, setError] = React.useState('')
  const [sent, setSent] = React.useState(false)
  const [validEmail, setValidEmail] = React.useState(false)

  let location = useLocation()
  let from = location?.state?.from?.pathname

  const requestMagicLink = email => {
    document.body.style.cursor = 'progress'
    setSent('requesting')
    let status = 0
    return fetch('/api/get_magic_link', {
      headers: {
        Authorization: 'Basic ' + btoa(`${email}:none`),
      },
      referrer: from || '',
      credentials: 'omit',
      // method: 'POST',
      // body: JSON.stringify({ email: email, ...(from ? { to: from } : {}) }),
    })
      .then(res => {
        status = res.status
        if (status === 500) {
          throw new Error('Unable to contact server. Please try again.')
        } else if (status === 401) {
          setError(res.statusText)
          setSent(false)
        } else {
          return res.json()
        }
      })
      .then(response => {
        if (!response) {
          return
        }
        document.body.style.cursor = 'default'
        if (status === 200) {
          setSent(true)
          // setMessage(response.message);
          return response
        } else if (status === 401) {
          setMessage(response.message)
        } else if (response.error !== undefined) {
          throw new Error(response.error)
        }
      })
      .catch(error => {
        document.body.style.cursor = 'default'
        setError(error.message)
        setSent(false)
        throw error
      })
      .finally(() => (document.body.style.cursor = 'default'))
  }

  const handleChange = ev => {
    if (sent) {
      return
    }
    setValidEmail(prevValidEmail => {
      const currValid = emailRegex.test(emailInput.current.value)
      if (currValid && !prevValidEmail) {
        if (error === invalidEmailMessage) {
          setError('')
        }
      }
      return currValid
    })
    if (ev.type === 'keyup' && ev.key !== 'Enter') {
      return
    } else if (
      emailInput.current.value.length === 0 ||
      !emailRegex.test(emailInput.current.value)
    ) {
      setError(invalidEmailMessage)
    } else {
      setError('')
      requestMagicLink(emailInput.current.value).catch(({ message }) => {
        console.log(message)
      })
    }
  }
  const validEmailColor = useColorModeValue('green.400', 'green.700')

  return (
    <Flex
      minH={'calc(100vh + 60px)'}
      transform={'translateY(-78.25px)'}
      align={'center'}
      justify={'center'}
      py={12}
      as={'main'}
      bg={useColorModeValue('white', 'gray.800')}
    >
      <Stack
        boxShadow={'2xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        p={10}
        spacing={8}
        align={'center'}
        minWidth={{ base: 'xs', md: 'sm' }}
      >
        <Logo h={24} _hover={{ filter: 'brightness(1.2)' }} />
        <Heading
          textTransform={'uppercase'}
          letterSpacing={0.5}
          fontSize={{ base: '2xl', md: '3xl' }}
          color={useColorModeValue('gray.500', 'gray.200')}
        >
          Sign in
        </Heading>
        <Stack
          spacing={4}
          // direction={{ base: 'column', md: 'row' }}
          w={'full'}
          as={'form'}
          noValidate
          onSubmit={e => {
            e.preventDefault()
            handleChange(e)
          }}
        >
          <FormControl id={'email'} isRequired>
            <Input
              placeholder={'your-email@example.com'}
              type={'email'}
              autoComplete={'email'}
              autoFocus
              ref={emailInput}
              onKeyUp={handleChange}
              isRequired
              color={useColorModeValue('gray.800', 'gray.200')}
              bg={useColorModeValue('gray.100', 'gray.600')}
              // rounded="none"
              borderColor={useColorModeValue('gray.100', 'gray.600')}
              _focus={{
                bg: useColorModeValue('gray.200', 'gray.800'),
                ...(validEmail ? { borderColor: validEmailColor } : {}),
              }}
            />
          </FormControl>
          <Button
            fontFamily={'"Nunito Sans", sans-serif'}
            fontWeight={'extrabold'}
            textTransform={'uppercase'}
            letterSpacing={0.25}
            // isDisabled={sent || !validEmail}
            isDisabled={sent}
            isLoading={sent === 'requesting'}
            loadingText={
              sent === 'requesting' ? 'Requesting Link' : 'Magic Link Sent'
            }
            spinnerPlacement={'end'}
            rightIcon={sent === true ? <FaCheckCircle /> : <EmailIcon />}
            bg={sent !== true ? 'brand.500' : 'green.600'}
            textShadow={'rgb(0 0 0 / 15%) 0px 3px 8px'}
            // rounded={'none'}
            color={'white'}
            _hover={{
              bg: 'cyan.400',
              // transition: 'all 0.1s ease 0s',
            }}
            _focus={{ bg: 'orange.600' }}
            onClick={handleChange}
          >
            {!sent ? 'Send Magic Link' : 'Magic Link Sent'}
          </Button>
        </Stack>
        {error !== '' ? (
          <Alert
            status="error"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={5}
          >
            <AlertIcon boxSize="32px" mr={0} mb={4} />
            <AlertDescription maxWidth="xs">{error}</AlertDescription>
          </Alert>
        ) : null}
        {message.length > 0 ? (
          <Alert
            status="info"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={5}
          >
            <AlertIcon boxSize="32px" mr={0} mb={4} />
            <AlertDescription maxWidth="xs">{message}</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setMessage('')}
            />
          </Alert>
        ) : null}
      </Stack>
    </Flex>
  )
}
