import React from 'react'
import Chart from 'react-apexcharts'
import { Box } from '@chakra-ui/react'
export default function Databar({
  value,
  positiveColor = '#0000FF',
  negativeColor = '#FF0000',
  max,
}) {
  const height = 55
  const [options, setOptions] = React.useState({
    chart: {
      type: 'bar',
      selection: { enabled: false },
      stacked: true,
      toolbar: { show: false },
      // offsetY: -height / 2 - 3,
      fontFamily: 'Inter',
      sparkline: { enabled: true },
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: {
        filter: {
          type: 'none' /* none, lighten, darken */,
        },
      },
    },
    colors: [({ value }) => (value < 0 ? negativeColor : positiveColor)],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%',
        columnWidth: '100%',
      },
    },
    // dataLabels: {
    //   enabled: false,
    //   formatter: function (val) {
    //     return Math.abs(Math.round(val)) + '%'
    //   },
    //   textAnchor: 'end',
    //   // offsetX: -100,
    //   style: {
    //     fontSize: '12px',
    //     fontFamily: 'Roboto',
    //     colors: ['#000'],
    //   },
    // },
    stroke: {
      width: 1,
      // colors: ['#fff'],
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    xaxis: {
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val)) + '%'
        },
        show: false,
      },
      floating: true,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      min: -max,
      max: max,
      title: {},
      show: false,
      floating: true,
    },
    annotations: {
      xaxis: [
        {
          x: 0,
          // strokeDashArray: 0,
          borderColor: '#333',
          // fillColor: "#c2c2c2",
          // opacity: 0.8,
          // offsetX: 0,
          // offsetY: 0
        },
      ],
    },
    tooltip: {
      enabled: false,
      x: {
        formatter: function (val) {
          return val
        },
      },
      y: {
        formatter: function (val) {
          return Math.abs(val) + '%'
        },
      },
    },
  })
  React.useEffect(() => {
    setOptions(prev => ({
      ...prev,
      yaxis: { ...prev.yaxis, min: -max, max: max },
    }))
  }, [max])
  return (
    // <Box maxH={'18px'} height={'18px'}>
    <Box maxH={height + 'px'}>
      <Chart
        options={options}
        // series={series}
        series={[{ data: [value] }]}
        type="bar"
        height={height}
        width="200"
        // width={'100%'}
        // height={350}
      />
    </Box>
  )
}
