import { groupBy, mapValues, maxBy, meanBy } from 'lodash'
import { medianBy } from './utils/median'

export const techAttributes = [
  {
    theme: 'Convenience',
    statement: 'Is simple and easy for customers to use/access',
    industryScore: 84.6263736263736,
    importance: 7.68787478185916,
    stdScore: 14.66147156,
  },
  {
    theme: 'Convenience',
    statement: 'Is available when customers need them',
    industryScore: 83.1758241758242,
    importance: 8.79463556225926,
    stdScore: 11.46684875,
  },
  {
    theme: 'Reliability',
    statement: 'Consistently meets customers’ expectations',
    industryScore: 83.0989010989011,
    importance: 9.81631474657642,
    stdScore: 13.91868276,
  },
  {
    theme: 'Reliability',
    statement: 'Has fast resolution of customers’ problems',
    industryScore: 83.7472527472527,
    importance: 4.15262258277116,
    stdScore: 13.24377521,
  },
  {
    theme: 'Reliability',
    statement: 'Listens to customers/is responsive',
    industryScore: 84.1318681318681,
    importance: 5.66809567286583,
    stdScore: 12.24303442,
  },
  {
    theme: 'Trust',
    statement: 'Protects customers’ data',
    industryScore: 85.4505494505494,
    importance: 4.21108439193772,
    stdScore: 11.28604546,
  },
  {
    theme: 'Trust',
    statement: 'Respects customers’ privacy',
    industryScore: 84.1318681318681,
    importance: 12.2170877894643,
    stdScore: 14.19955322,
  },
  {
    theme: 'Trust',
    statement: 'Ratings & reviews are available',
    industryScore: 82.8461538461538,
    importance: 2.36557875327102,
    stdScore: 13.55251626,
  },
  {
    theme: 'Trust',
    statement: 'Is knowledgeable about their product/services',
    industryScore: 85.4945054945055,
    importance: 7.30917203593231,
    stdScore: 12.31555239,
  },
  {
    theme: 'Personalization',
    statement: 'Knows their customers',
    industryScore: 81.6483516483516,
    importance: 2.26861446479102,
    stdScore: 11.88101873,
  },
  {
    theme: 'Personalization',
    statement: 'Understands the customers’ needs',
    industryScore: 82.8461538461538,
    importance: 3.82877311587219,
    stdScore: 11.40290822,
  },
  {
    theme: 'Personalization',
    statement: 'Communications are personalized to the customer',
    industryScore: 83.7142857142857,
    importance: 1.6450888957221,
    stdScore: 13.57212963,
  },
  {
    theme: 'Personalization',
    statement: 'Recommends products/services that are relevant to the customer',
    industryScore: 82.7252747252747,
    importance: 5.52303447299245,
    stdScore: 15.87820375,
  },
  {
    theme: 'Personalization',
    statement:
      'Has a consistent experience across all interactions (online/offline)',
    industryScore: 82.7032967032967,
    importance: 9.05825671845834,
    stdScore: 16.35229592,
  },
  {
    theme: 'Value',
    statement: 'Offers loyalty programs that reward customers',
    industryScore: 78.1648351648352,
    importance: 8.60993819200152,
    stdScore: 24.32064142,
  },
  {
    theme: 'Delight',
    statement: 'Surprises/delights customers',
    industryScore: 82.3076923076923,
    importance: 1.30804079670002,
    stdScore: 15.7949386,
  },
  {
    theme: 'Expertise',
    statement:
      'Educates customers about how to better use their product/service',
    industryScore: 81.4835164835165,
    importance: 5.53578702652516,
    stdScore: 15.68211963,
  },
]
// export const themes = attributes.reduce(
//   (themes, { theme }) =>
//     !themes.includes(theme) ? [...themes, theme] : themes,
//   []
// )

export const techAttributeThemeColors = {
  Convenience: 'blue.500',
  Reliability: 'green.500',
  Trust: 'purple.500',
  Personalization: 'pink.500',
  Value: 'yellow.500',
  Delight: 'blue.600',
  Expertise: 'green.600',
}
export const techThemeImportance = [
  { theme: 'Convenience', importance: 22.0425505324935 },
  { theme: 'Reliability', importance: 18.9708571135365 },
  { theme: 'Trust', importance: 20.0191874462199 },
  { theme: 'Personalization', importance: 12.0780898901748 },
  { theme: 'Value', importance: 15.6155464342657 },
  { theme: 'Delight', importance: 3.65736483434036 },
  { theme: 'Expertise', importance: 7.61640374896915 },
]
// export const attributeThemeColorsTokenized = Object.keys(
//   attributeThemeColors
// ).map(attribute => useToken('colors', attributeThemeColors[attribute]))
export const techThemeIndustryPerformance = mapValues(
  groupBy(techAttributes, 'theme'),
  statements => meanBy(statements, 'industryScore')
)

export const techThemes = techThemeImportance.map(themeEntry => ({
  ...themeEntry,
  color: techAttributeThemeColors[themeEntry.theme],
  industryScore: techThemeIndustryPerformance[themeEntry.theme],
  importance: techThemeImportance.find(
    entry => entry.theme === themeEntry.theme
  ).importance,
}))

export const techMedianThemeImportance = medianBy(techThemes, 'importance')
export const techMedianThemePerformance = medianBy(techThemes, 'industryScore')
export const techMedianAttributeImportance = medianBy(
  techAttributes,
  'importance'
)
// export const techMedianAttributePerformance = medianBy(techAttributes, 'industryScore')
export const techMaxAttributeImportance = maxBy(
  techAttributes,
  'importance'
).importance
export const techAttributesOfImportantThemes = techAttributes.filter(
  attribute =>
    techThemes.find(t => t.theme === attribute.theme).importance >
    techMedianThemeImportance
)
